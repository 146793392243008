<template>
  <div>RequestReceived</div>
</template>

<script>
export default {
  name: "RequestReceived",
  components: {},
};
</script>

<style scoped></style>
