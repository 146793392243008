<template>
  <section>
    <base-card>
      <h3>{{ fullName }}</h3>
      <h3>${{ rate }}/hour</h3>
    </base-card>
  </section>
  <section>
    <base-card>
      <header>
        <h2>Interested? Write us</h2>
        <base-button link :to="contactLink">Contact</base-button>
      </header>
      <router-view></router-view>
    </base-card>
  </section>
  <section>
    <base-card>
      <base-badge
        v-for="area in areas"
        :key="area"
        :type="area"
        :title="area"
      ></base-badge>
      <p>{{ description }}</p>
    </base-card>
  </section>
</template>

<script>
export default {
  name: "CoachDetail",
  props: ["id"],
  data() {
    return {
      selectedCoach: null,
    };
  },

  computed: {
    fullName() {
      return this.selectedCoach.firstName + " " + this.selectedCoach.lastName;
    },
    description() {
      return this.selectedCoach.description;
    },
    areas() {
      return this.selectedCoach.areas;
    },
    rate() {
      return this.selectedCoach.hourlyRate;
    },
    contactLink() {
      return this.$route.path + "/" + this.id + "/contact";
    },
  },

  created() {
    this.selectedCoach = this.$store.getters["coachesKey/coaches"].find(
      (coach) => coach.id === this.id
    );
  },
};
</script>

<style scoped></style>
